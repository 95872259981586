import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Error from './components/Error';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeSix from './components/HomeSix';

function Routes() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <div className="appie-visible active">
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/" component={HomeSix} />
                        <Route component={Error} />
                    </Switch>
                </ScrollToTop>
            </Router>
        </div>
    );
}

export default Routes;
