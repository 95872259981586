import React from 'react';
import logo from '../../assets/images/favicon.png';

function FooterHomeSix() {
    return (
        <>
            <section className="appie-footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget footer-about-widget-6">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="logo" className="appie-logo-box" />
                                    </a>
                                </div>
                                <p>Gmee est le réseau social nouvelle génération.</p>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/profile.php?id=100072197120766"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/gmeeapp/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://apps.apple.com/us/app/gmee/id1598656004"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-apple"></i> Télécharger pour
                                                iOS
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="item-2"
                                                href="https://play.google.com/store/apps/details?id=com.really.gmee&hl=fr"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-google-play"></i> Télécharger
                                                pour Android
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2023 Nox Industries. Tous droits réservés.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeSix;
