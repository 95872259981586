import React, { useEffect } from 'react';
import logo from '../../assets/images/favicon.png';
import StickyMenu from '../../lib/StickyMenu';

function HeaderHomeSix() {
    useEffect(() => {
        StickyMenu();
    });
    return (
        <>
            <header className="appie-header-area appie-sticky">
                <div className="container">
                    <div className="header-nav-box header-nav-box-6">
                        <div
                            className="row align-items-center justify-content-space-between"
                            style={{
                                justifyContent: 'space-between',
                            }}
                        >
                            <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                                <div className="appie-logo-box">
                                    <a href="/">
                                        <img src={logo} alt="logo" className="appie-logo-box" />
                                    </a>
                                </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                                <div className="appie-header-main-menu">
                                    <Navigation />
                                </div>
                            </div> */}
                            <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                                <div className="appie-btn-box text-right">
                                    <span className="login-btn">
                                        Le réseau social nouvelle génération ❤️
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default HeaderHomeSix;
