import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FaqHomeOne from '../HomeOne/FaqHomeOne';
import FeaturesHomeOne from '../HomeOne/FeaturesHomeOne';
import ServicesHomeOne from '../HomeOne/ServicesHomeOne';
import Drawer from '../Mobile/Drawer';
import FooterHomeSix from './FooterHomeSix';
import HeaderHomeSix from './HeaderHomeSix';
import HeroHomeSix from './HeroHomeSix';
import WorkPartHomeEight from './WorkPartHomeEight';

function HomeSix() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeSix action={drawerAction.toggle} />
            <HeroHomeSix />
            <BackToTop className="back-to-top-5" />
            <ServicesHomeOne />
            <FeaturesHomeOne className="appie-features-6-area" />
            <FaqHomeOne className="pt-90" />

            <WorkPartHomeEight />

            <FooterHomeSix />
        </>
    );
}

export default HomeSix;
