import React from 'react';
import workThumb from '../../assets/images/get-started/Home_Dark.png';

function WorkPartHomeEight() {
    return (
        <>
            <div className="appie-how-it-work-area pt-10 pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="how-it-work-thumb text-center">
                                <img
                                    src={workThumb}
                                    alt=""
                                    width="230"
                                    style={{
                                        transform: 'rotate(10deg)',
                                        paddingTop: '2rem',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-how-it-work-content">
                                <h2 className="title">Comment ça marche ?</h2>
                                <p>
                                    Pour profiter un maximum de Gmee rien de plus simple, il vous
                                    suffit de suivre ces 4 étapes et le tour est joué
                                </p>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>1</span>
                                            <h5 className="title">
                                                Télécharger <br />
                                                l'application
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>2</span>
                                            <h5 className="title">
                                                Créer un <br />
                                                compte gratuitement
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>3</span>
                                            <h5 className="title">
                                                Trouvez vos <br />
                                                comptes favoris
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>4</span>
                                            <h5 className="title">
                                                Partagez de <br />
                                                bons moments
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WorkPartHomeEight;
