import React from 'react';
import heroThumbOne from '../../assets/images/get-started/Home_Dark.png';
import heroThumbTwo from '../../assets/images/get-started/Home_Light.png';

function HeroHomeSix() {
    return (
        <>
            <section className="appie-hero-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content appie-hero-content-6">
                                <span>Bienvenu sur Gmee.</span>
                                <h1 className="appie-title">Un goût de nostalgie avec Gmee.</h1>
                                <p>
                                    Le réseau social 100% français qui respecte vos données. Venez
                                    donc jeter un oeil 😉
                                </p>
                                <ul>
                                    <li>
                                        <a
                                            href="https://apps.apple.com/us/app/gmee/id1598656004"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-apple"></i>
                                            <span>
                                                Disponible sur <span>App Store</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="item-2"
                                            href="https://play.google.com/store/apps/details?id=com.really.gmee&hl=fr"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-google-play"></i>
                                            <span>
                                                Disponible sur <span>Google Play</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={heroThumbOne} alt="" width="310" height="616" />
                                </div>
                                <div
                                    className="thumb-2 wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={heroThumbTwo} alt="" width="145" height="308" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroHomeSix;
